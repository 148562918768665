import React from "react";

import * as Styled from "./PhotosWave.styles";
import Stroke1 from "src/assets/svgs/stroke1.svg";
import Stroke2 from "src/assets/svgs/stroke2.svg";
import HeartsA from "src/assets/svgs/three-hearts--asc.svg";
import HeartsD from "src/assets/svgs/three-hearts--desc.svg";
import photo1 from "src/assets/images/photos-wave-1.png";
import photo2 from "src/assets/images/photos-wave-2.png";
import photo3 from "src/assets/images/photos-wave-3.png";
import photo4 from "src/assets/images/photos-wave-4.png";
import photo5 from "src/assets/images/photos-wave-5.png";
import photo6 from "src/assets/images/photos-wave-6.png";
import photoIndia from "src/assets/images/india.jpg";
import photoKenya from "src/assets/images/kenya.jpg";
import photoFactoryFarming from "src/assets/images/factory-farming.jpg";

const PHOTOS = [
  {
    img: photo2,
    place: "Kongo",
    desc: "400 000 children face starvation.",
  },
  {
    img: photoIndia,
    place: "India",
    desc: "176 million people live in extreme poverty",
    credits:
      "Photo: Zoriah Miller for Dollar Street 2015 (Free to use under CC BY 4.0)",
  },
  {
    img: photoKenya,
    place: "Kenya",
    desc: "16 million lack access to safe water.",
  },
  {
    img: photo5,
    place: "Madagascar",
    desc: "Undernourishment and hunger rates were at 42% in 2018.",
  },
  {
    img: photoFactoryFarming,
    place: "Globally",
    desc: "Over 50 billion animals live in factory farms.",
  },
  // {
  //   img: photo6,
  //   place: "Madagascar",
  //   desc: "2% die each month of starvation.",
  // },
];

const PhotosWave = () => (
  <Styled.Container>
    {PHOTOS.map(({ img, place, desc, credits }, i) => (
      <Styled.ImgRelativeWrapper key={i}>
        {i === 1 && (
          <>
            <Stroke2 className="stroke2" />
            <HeartsA className="hearts-left" />
          </>
        )}
        {i === 3 && (
          <>
            <Stroke1 className="stroke1" />
            <HeartsD className="hearts-right" />
          </>
        )}
        <Styled.ImgWrapper>
          <img src={img} alt={place} />
          <Styled.Gradient />
          <Styled.Description>
            {place}
            <span>{desc}</span>
            {credits && <span style={{ fontSize: "9px" }}>{credits}</span>}
          </Styled.Description>
        </Styled.ImgWrapper>
      </Styled.ImgRelativeWrapper>
    ))}
  </Styled.Container>
);

export default PhotosWave;
