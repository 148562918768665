import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";
import Arrow from "src/assets/images/arrow.svg";

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  ${mdUp} {
    align-items: flex-start;
  }

  .heart-big {
    display: none;

    ${mdUp} {
      display: block;
      position: absolute;
      top: -120px;
      right: 100px;
      width: 88px;
      height: auto;
    }

    ${lgUp} {
      width: 104px;
      top: -110px;
      right: 170px;
    }
  }

  .heart-small {
    display: none;

    ${mdUp} {
      display: block;
      position: absolute;
      width: 42px;
      height: auto;
      top: -50px;
      right: 0;
      transform: rotate(35deg);
    }

    ${lgUp} {
      width: 56px;
      top: -30px;
      right: 30px;
    }
  }
`;

export const TextContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: -100px;
  max-width: 500px;

  ${mdUp} {
    align-items: flex-start;
    margin-top: -60px;
    max-width: 516px;
  }

  ${lgUp} {
    margin-top: -140px;
    max-width: 640px;
  }

  > h1 {
    margin-bottom: 26px;
    padding: 0 30px;

    ${mdUp} {
      margin-bottom: 40px;
      padding: 0;
      text-align: left;
    }
  }

  > p {
    padding: 0 15px;
    text-align: center;

    ${mdUp} {
      padding: 0;
      text-align: left;
    }
  }
`;

export const RelativeContainer = styled.div`
  margin-bottom: 60px;
  position: relative;
  display: none;

  ${mdUp} {
    display: block;
  }

  > svg {
    position: absolute;
    width: 248px;
    top: -50px;
    transform: scaleY(-1);
    left: 102px;

    ${lgUp} {
      width: 300px;
      left: 20px;
    }
  }
`;

export const FeaturesMobile = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 70px 0 50px;
  padding: 0 24px;
  width: 100%;
  position: relative;

  ${mdUp} {
    display: none;
  }

  > svg {
    position: absolute;
    z-index: -1;
    top: -55px;
    transform: scaleY(-1);
    left: 40px;
  }
`;

export const FeaturesDesktop = styled.div`
  margin-top: 70px;
  padding-bottom: 60px;
  width: 530px;

  ${lgUp} {
    margin-top: 80px;
  }

  .slick-list {
    overflow: visible;
    position: static;
  }

  .slick-slider {
    position: static;
  }

  .slick-arrow {
    bottom: 7px;
    display: grid !important;
    font-size: 0;
    height: 12px;
    line-height: 0;
    place-items: center;
    top: auto;
    width: 22px;
    z-index: 2;

    ::before {
      content: url(${Arrow});
      font-size: 0;
      line-height: 0;
    }

    &.slick-prev {
      left: calc(50% - 185px);

      ::before {
        transform: rotate(180deg);
      }
    }

    &.slick-next {
      right: calc(50% - 185px);
    }
  }

  .slick-dots {
    bottom: 0;

    li {
      margin: 0;
      width: 60px;
    }

    button {
      margin: 0;
      padding: 0;
      width: 100%;

      ::before {
        background-color: ${({ theme }) => theme.textDark};
        content: "";
        height: 2px;
        width: 100%;
      }
    }
  }
`;

export const Feature = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.white};
  border-radius: 12px;
  display: flex !important;
  flex-direction: column;
  max-width: 500px;
  padding: 26px 18px 40px;
  width: 100%;

  ${mdUp} {
    height: 527px;
    margin-right: 30px;
    padding: 50px 44px 0;
    width: 480px;
  }

  :not(:last-child) {
    margin-bottom: 20px;
  }

  svg {
    height: 130px;
    margin-bottom: 10px;

    ${mdUp} {
      height: 200px;
      margin-bottom: 16px;
    }
  }

  h2 {
    margin-bottom: 12px;

    ${mdUp} {
      margin-bottom: 20px;
    }
  }

  a {
    color: ${({ theme }) => theme.textDark};
    text-decoration: underline;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  margin-bottom: 60px;
  place-items: center;

  ${mdUp} {
    margin-bottom: 120px;
  }

  ${lgUp} {
    margin-bottom: 161px;
  }
`;
