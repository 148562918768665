export const sections = {
  problem: "problem",
  solution: "solution",
  whyUs: "why-us",
  partners: "partners",
  faq: "faq",
};

export const links = {
  contactMail: "mailto:hello@altruisto.com",
  signup: (ref) => `https://altruisto.com/signup${ref ? `?ref=${ref}` : ""}`,
  signin: (ref) => `https://altruisto.com/login${ref ? `?ref=${ref}` : ""}`,
  extensionChrome:
    "https://chrome.google.com/webstore/detail/altruistocom/djennkbadhfcmhlbejdidgmdgnacbcmi",
  extensionFirefox:
    "https://addons.mozilla.org/en-US/firefox/addon/altruisto-com/",
  blog: "https://altruisto.com/blog",
  partners: "https://altruisto.com/partners",
  privacy: "https://altruisto.com/privacy-policy.html",
  terms: "https://altruisto.com/terms-of-service",
  github: "https://github.com/Altruisto/altruisto",
  contact: "https://altruisto.com/contact",
};
