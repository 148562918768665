import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const CheckLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  min-height: 32px;
  padding-left: 50px;
  position: relative;
`;

export const CheckInput = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`;

export const Checkmark = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.input.disabled};
  color: ${({ theme }) => theme.white};
  display: grid;
  height: 32px;
  left: 0;
  place-items: center;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 32px;

  ${({ checked, theme }) =>
    checked &&
    `
  background-color: ${theme.secondary};
  border: 1px solid ${theme.secondary};
  `};
`;
