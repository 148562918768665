import React from "react";

import * as Styled from "./ThreeStepsSection.styles";
import { H2, Paragraph, Button } from "src/components/common";
import { InnerContainer } from "src/components/Layout/Layout.styles";
import Install from "src/assets/svgs/install.svg";
import Shop from "src/assets/svgs/shop.svg";
import Make from "src/assets/svgs/make.svg";
import Chevron from "src/assets/svgs/chevron.svg";
import HeartD from "src/assets/svgs/heart--dark.svg";
import { links } from "src/constants/constants";
import { useReferredBy } from "../../hooks/useReferredBy";
import { LinkButton } from "../LinkButton/LinkButton";

const STEPS = [
  {
    icon: <Install />,
    title: "Install extension",
    desc:
      "Start by registering your account and installing our extension. <span>The extension is safe</span>, open source, and respects your privacy.",
  },
  {
    icon: <Shop />,
    title: "Choose a store and shop",
    desc:
      "When you shop online, a button will appear. After clicking it, a portion of the money you pay will go to selected charities.<br />You pay <span>no additional money!</span>",
  },
  {
    icon: <Make />,
    title: "Make a difference",
    desc:
      "We send the donation from your purchase to <span>carefully chosen, underfunded charities</span> that are extremely effective.",
  },
];

const ThreeStepsSection = () => {
  const referredBy = useReferredBy();

  return (
    <InnerContainer id="three-steps">
      <Styled.Content>
        <HeartD className="heart-small" />
        <HeartD className="heart-big" />
        <Styled.Steps>
          {STEPS.map((e, i) => (
            <React.Fragment key={i}>
              <Styled.Step>
                {e.icon}
                <H2>{e.title}</H2>
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: e.desc }}
                  center
                />
              </Styled.Step>
              {STEPS.length - 1 > i && (
                <Styled.Circle>
                  <Chevron />
                </Styled.Circle>
              )}
            </React.Fragment>
          ))}
        </Styled.Steps>
        <LinkButton />
      </Styled.Content>
    </InnerContainer>
  );
};

export default ThreeStepsSection;
