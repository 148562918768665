import React, { useState } from "react";

import * as Styled from "./Footer.styles";
import { InnerContainer } from "src/components";
import { H2, TextInput, Checkbox } from "src/components/common";
import LogoDark from "src/assets/svgs/logo--dark.svg";
import Facebook from "src/assets/svgs/facebook.svg";
import Twitter from "src/assets/svgs/twitter.svg";
import Linkedin from "src/assets/svgs/linkedin.svg";
import Instagram from "src/assets/svgs/instagram.svg";
import Arrow from "src/assets/svgs/arrow.svg";
import { links } from "src/constants/constants";

const MENU_ITEMS = [
  {
    name: "List of our partners",
    link: links.partners,
  },
  {
    name: "Privacy policy",
    link: links.privacy,
  },
  {
    name: "Terms of service",
    link: links.terms,
  },
  {
    name: "Github",
    link: links.github,
  },
  {
    name: "Contact us",
    link: links.contact,
  },
];

const SOCIAL_PLATFORMS = [
  {
    Icon: Facebook,
    name: "Facebook",
    link: "https://facebook.com/altruistoCom",
  },
  {
    Icon: Twitter,
    name: "Twitter",
    link: "https://twitter.com/altruistoCom",
  },
  {
    Icon: Linkedin,
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/10895330",
  },
  {
    Icon: Instagram,
    name: "Instagram",
    link: "https://www.instagram.com/altruistocom/",
  },
];

// TODO: add scroll to top
const Footer = () => {
  const [emailValue, setEmailValue] = useState("");
  const [consentValue, setConsentValue] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    if (!consentValue) {
      e.preventDefault();
      setError("You need to agree to receive comunication.");
    }
  };

  const handleCheck = () => {
    if (!consentValue) {
      setError("");
    }

    setConsentValue(!consentValue);
  };

  return (
    <Styled.Wrapper>
      <InnerContainer>
        <Styled.Content>
          <Styled.Info>
            <LogoDark />
            <p>
              Altruisto is a not-for-profit initiative. We donate our profits to
              Against Malaria Foundation, Schisotomasis Control Initiative, and
              GiveDirectly.
            </p>
            <span>
              Let's talk: <a href={links.contactMail}>hello@altruisto.com</a>
            </span>
            <Styled.Social>
              {SOCIAL_PLATFORMS.map(({ Icon, name, link }) => (
                <Styled.SocialBtn key={name} href={link} aria-label={name}>
                  <Icon />
                </Styled.SocialBtn>
              ))}
            </Styled.Social>
          </Styled.Info>
          <Styled.Menu>
            <H2>Menu</H2>
            <Styled.MenuItems>
              {MENU_ITEMS.map(({ name, link }) => (
                <li key={name}>
                  <a href={link} rel="noopener noreferrer" target="_blank">
                    {name}
                  </a>
                </li>
              ))}
            </Styled.MenuItems>
          </Styled.Menu>
          <Styled.Newsletter>
            <H2>Join our newsletter</H2>
            <p>
              Subscribe to get updates and notifications about Altruisto and our
              mission.
            </p>
            <div id="mc_embed_signup">
              <Styled.Form
                action={
                  "https://altruisto.us11.list-manage.com/subscribe/post?u=f12e591f7f693dc0afcd3f188&id=0c047e44bf"
                }
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
                onSubmit={(e) => handleSubmit(e)}
              >
                <Styled.InputWrapper>
                  <TextInput
                    label="E-mail"
                    placeholder="E-mail address"
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    required
                    value={emailValue}
                    setValue={setEmailValue}
                    error={error}
                  />
                  <Styled.SubmitBtn
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                  >
                    <Arrow />
                  </Styled.SubmitBtn>
                </Styled.InputWrapper>
                <Checkbox
                  label="I consent receiving communications from Altruisto at the e-mail provided above"
                  id="email-consent"
                  name="email-consent"
                  checked={consentValue}
                  setChecked={handleCheck}
                />
              </Styled.Form>
            </div>
          </Styled.Newsletter>
        </Styled.Content>
      </InnerContainer>
      <Styled.Copyrights>
        <InnerContainer>
          <Styled.CopyrightsContent>
            <p>Copyright © Altruisto.com 2021</p>
            <p>
              The website is managed by Well-Managed World C.I.C. a community
              interest company based in Rotherham, United Kingdom.
            </p>
          </Styled.CopyrightsContent>
        </InnerContainer>
      </Styled.Copyrights>
    </Styled.Wrapper>
  );
};

export default Footer;
