import styled from "styled-components";

import { mdUp } from "src/styles/breakpoints";

export const ButtonPrimary = styled.button`
  background: ${({ theme }) => theme.gradient.primary};
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.white};
  display: grid;
  font-size: 13px;
  height: ${({ small }) => (small ? "56px" : "64px")};
  place-items: center;
  transition: all 0.3s ease-out;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "220px")};
  margin: ${({ margin }) => margin};

  :hover {
    background: ${({ theme, disabled }) =>
      !disabled && theme.gradient.secondary};
  }

  ${mdUp} {
    width: 220px;
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    background: ${theme.button.disabled};
      cursor: not-allowed;
    `}
`;

export const ButtonSecondary = styled(ButtonPrimary)`
  background: ${({ theme }) => theme.button.secondary};
  color: ${({ theme }) => theme.primary};

  :hover {
    background: ${({ theme, disabled }) =>
      !disabled && theme.button.secondaryActive};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.text};
      background: ${theme.button.secondaryDisabled};
    `}
`;

export const ButtonBlurred = styled(ButtonPrimary)`
  backdrop-filter: blur(15px);
  background: rgba(246, 243, 238, 0.1);

  :hover {
    background: rgba(246, 243, 238, 0.2);
  }
`;

export const ButtonTertiary = styled(ButtonPrimary)`
  background: ${({ theme }) => theme.button.tertiary};

  :hover {
    background: ${({ theme }) => theme.button.tertiary};
  }
`;
