import React from "react";

import * as Styled from "./PartnersSection.styles";
import { OuterContainer, InnerContainer } from "src/components";
import Aliexpress from "src/assets/images/aliexpress.svg";
import Ebay from "src/assets/images/ebay.svg";
import Microsoft from "src/assets/images/microsoft.svg";
import Booking from "src/assets/images/booking.svg";
import Barnes from "src/assets/images/barnes.svg";
import Etsy from "src/assets/images/etsy.svg";

const PartnersSection = () => (
  <OuterContainer bgColor="background" id="partners">
    <InnerContainer>
      <Styled.Content>
        <Styled.Heading>OUR PARTNERS</Styled.Heading>
        <Styled.Partners>
          {/* <img src={Ebay} alt="Ebay" /> */}
          <img src={Microsoft} alt="Microsoft" />
          <img src={Aliexpress} alt="Aliexpress" />
          <img src={Booking} alt="Booking.com" />
          {/* <img src={Barnes} alt="Barnes & Nobles" /> */}
          <img src={Etsy} alt="Etsy" />
        </Styled.Partners>
      </Styled.Content>
    </InnerContainer>
  </OuterContainer>
);

export default PartnersSection;
