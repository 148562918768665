import React from "react";
import PropTypes from "prop-types";

import * as Styled from "./Button.styles";

const Button = ({
  secondary,
  blurred,
  children,
  disabled,
  margin,
  fullWidth,
  tertiary,
  link,
  href,
  className,
}) => {
  var ButtonComponent;

  if (secondary) {
    ButtonComponent = Styled.ButtonSecondary;
  } else if (blurred) {
    ButtonComponent = Styled.ButtonBlurred;
  } else if (tertiary) {
    ButtonComponent = Styled.ButtonTertiary;
  } else {
    ButtonComponent = Styled.ButtonPrimary;
  }

  return (
    <ButtonComponent
      secondary={secondary}
      blurred={blurred}
      disabled={disabled}
      margin={margin}
      fullWidth={fullWidth}
      as={link && "a"}
      href={href}
      className={className}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </ButtonComponent>
  );
};

Button.propTypes = {
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  blurred: PropTypes.bool,
  children: PropTypes.node,
  margin: PropTypes.string, // valid css margin shorthand
  fullWidth: PropTypes.bool,
  link: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
