import styled from "styled-components";

import { mdUp, lgUp, xlUp } from "src/styles/breakpoints";

export const Content = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  width: 100%;

  ${mdUp} {
    padding: 120px 0;
  }

  ${lgUp} {
    align-items: flex-start;
    flex-direction: row;
    padding: 160px 0;
  }
`;

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  max-width: 330px;
  width: 100%;
  position: relative;

  ${mdUp} {
    max-width: 500px;
    margin-bottom: 70px;
  }

  ${lgUp} {
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 24px 0 0;
    max-width: auto;
    position: sticky;
    top: 100px;
    width: 40%;
  }

  h1 {
    ${lgUp} {
      text-align: left;
    }
  }

  p {
    margin-top: 26px;
    text-align: center;

    ${mdUp} {
      margin-top: 40px;
      padding: 0 30px;
    }

    ${lgUp} {
      padding: 0;
      text-align: left;
    }
  }

  > svg {
    height: auto;
    position: absolute;
  }

  .heart-left {
    width: 40px;
    top: 90px;
    left: 15px;
    transform: rotate(-20deg);

    ${mdUp} {
      width: 60px;
      left: -70px;
      top: 110px;
    }

    ${lgUp} {
      left: 40px;
      top: auto;
      bottom: -200px;
      transform: rotate(40deg);
    }
  }

  .heart-right {
    width: 75px;
    top: -30px;
    right: 0;
    transform: rotate(40deg);

    ${mdUp} {
      width: 90px;
      top: -45px;
      right: -110px;
    }

    ${lgUp} {
      right: 40px;
      top: auto;
      bottom: -160px;
      width: 140px;
      transform: rotate(-15deg);
    }
  }
`;

export const FaqList = styled.div`
  position: relative;

  .stroke1--y {
    position: absolute;
    z-index: 1;
    width: 131px;
    left: -20px;
    top: -60px;

    ${mdUp} {
      width: 200px;
      top: -70px;
      left: -30px;
    }
  }

  .stroke1 {
    position: absolute;
    z-index: -1;
    width: 131px;
    right: -20px;
    top: 40px;
    transform: scaleY(-1);

    ${mdUp} {
      width: 206px;
      right: -30px;
    }

    ${lgUp} {
      right: -20px;
    }

    ${xlUp} {
      right: -30px;
    }
  }

  .stroke1--2 {
    position: absolute;
    z-index: -1;
    width: 131px;
    left: -24px;
    bottom: -40px;
    transform: scaleX(-1);

    ${mdUp} {
      width: 215px;
      left: -40px;
      bottom: -50px;
    }
  }
`;
