import * as React from "react";
import { useInView } from "react-intersection-observer";

import { GlobalStyle } from "src/styles/global";
import {
  Layout,
  HeroSection,
  PartnersSection,
  ProblemsSection,
  PhotosWave,
  CalculatorSection,
  ThreeStepsSection,
  ChangeWorldSection,
  PhotosSection,
  FaqSection,
  CtaSection,
  BlogSection,
} from "src/components";

const IndexPage = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: true,
  });

  return (
    <>
      <GlobalStyle />
      <Layout observerInView={inView}>
        <HeroSection observerRef={ref} />
        <PartnersSection />
        <ProblemsSection />
        <PhotosWave />
        <CalculatorSection />
        <ThreeStepsSection />
        <ChangeWorldSection />
        <PhotosSection />
        {/* testimonials section here */}
        <FaqSection />
        {/* <BlogSection /> */}
        <CtaSection />
      </Layout>
    </>
  );
};

export default IndexPage;
