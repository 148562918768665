import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

// for 100vw sections, InnerContainer should be nested inside
// bgColor - color name from theme, as a string
export const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme[bgColor] : "transparent"};
  z-index: 11;
`;

// default container
export const InnerContainer = styled.div`
  padding: 0 24px;
  width: 100%;
  position: ${({ relative }) => relative && "relative"};
  z-index: 1;

  ${mdUp} {
    padding: 0 50px;
  }

  ${lgUp} {
    padding: 0 20px;
    max-width: 1220px;
  }
`;
