import React, { useEffect } from "react";

import * as Styled from "./CtaSection.styles";
import { H1, Button } from "src/components/common";
import { InnerContainer } from "src/components/Layout/Layout.styles";
import Stroke1 from "src/assets/svgs/stroke1.svg";
import { links } from "src/constants/constants";
import { LinkButton } from "../LinkButton/LinkButton";

const CtaSection = () => {
  return (
    <Styled.OuterContainer>
      <Stroke1 className="stroke1" />
      <InnerContainer>
        <Styled.Content>
          <H1 light>
            Start making the <span>world a better place</span> with Altruisto!
          </H1>
          <p>
            Don’t be indifferent to helping. Register today and start helping
            with every single purchase.
          </p>
          <Styled.ButtonsWrapper>
            <LinkButton />
            <Button blurred link href={links.contactMail}>
              Contact us
            </Button>
          </Styled.ButtonsWrapper>
        </Styled.Content>
      </InnerContainer>
      <Stroke1 className="stroke1--2" />
    </Styled.OuterContainer>
  );
};

export default CtaSection;
