import React, { useEffect, useState } from "react";
import { isChrome, isFirefox, isMobile } from "react-device-detect";
import { links } from "../../constants/constants";
import { useReferredBy } from "../../hooks/useReferredBy";
import { Button } from "src/components/common";

export const LinkButton = () => {
  const referredBy = useReferredBy();
  const [linkTarget, setLinkTarget] = useState(links.signup(referredBy));
  const extensionAvailable = !isMobile && (isChrome || isFirefox);

  const link = () => {
    if (isChrome) {
      return links.extensionChrome;
    }
    if (isFirefox) {
      return links.extensionFirefox;
    }
    return links.signup(referredBy);
  };

  useEffect(() => {
    setLinkTarget(link());
  }, [extensionAvailable]);

  return extensionAvailable ? (
    <Button link href={linkTarget}>
      Install our extension
    </Button>
  ) : (
    <Button link href={links.signup(referredBy)}>
      Sign up for free
    </Button>
  );
};
