import React from "react";

import * as Styled from "./ProblemsSection.styles";
import { InnerContainer, ProblemsTabs, OuterContainer } from "src/components";
import { H1, Subheading } from "src/components/common";
import Heart from "src/assets/svgs/heart.svg";

const ProblemsSection = () => {
  return (
    <OuterContainer style={{ overflow: "hidden" }} id="problem">
      <InnerContainer>
        <Styled.Content>
          <Subheading>PROBLEM</Subheading>
          <Styled.TitleWrapper>
            <Heart className="heart1" />
            <Heart className="heart2" />
            <Heart className="heart3" />
            <Heart className="heart4" />
            <H1>
              Humanity
              <br />
              <span>faces&nbsp;many problems</span>
            </H1>
          </Styled.TitleWrapper>
          <ProblemsTabs />
        </Styled.Content>
      </InnerContainer>
    </OuterContainer>
  );
};

export default ProblemsSection;
