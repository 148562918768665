import React, { useState } from "react";

import * as Styled from "./Accordion.styles";
import Chevron from "src/assets/svgs/chevron.svg";

const Accordion = ({ question, answer }) => {
  const [answerOpen, setAnswerOpen] = useState(false);
  return (
    <Styled.Accordion answerOpen={answerOpen}>
      <Styled.Question onClick={() => setAnswerOpen(!answerOpen)}>
        <span>{question}</span>
        <Styled.IconWrapper answerOpen={answerOpen}>
          <Chevron />
        </Styled.IconWrapper>
      </Styled.Question>
      <Styled.Answer answerOpen={answerOpen}>
        <Styled.AnswerText
          as="div"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </Styled.Answer>
    </Styled.Accordion>
  );
};

export default Accordion;
