import styled from "styled-components";

import { mdUp, lgUp, xlUp } from "src/styles/breakpoints";

export const Wrapper = styled.footer`
  display: grid;
  margin-top: auto;
  place-items: center;
  width: 100%;

  a {
    color: ${({ theme }) => theme.black};
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  font-size: 12px;
  line-height: 20px;
  padding: 82px 0 44px;

  ${mdUp} {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, auto);
    padding: 128px 0 66px;
    grid-template-areas:
      "info info info info menu menu menu menu"
      "news news news news news .    .    .   ";
  }

  ${lgUp} {
    grid-template-areas: "info menu news";
    grid-template-columns: repeat(3, 1fr);
    padding: 164px 0 90px;
  }

  ${xlUp} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "info info info .    menu menu menu .    news news news news";
  }
`;

export const Info = styled.div`
  grid-area: info;
  margin-right: 68px;
  min-height: 130px;

  > svg {
    margin-bottom: 20px;
  }

  > span {
    display: block;
    font-size: 14px;
    margin-top: 16px;
  }

  ${mdUp} {
    margin: 0;
  }
`;

export const Social = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const SocialBtn = styled.a`
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  display: grid;
  height: 52px;
  place-items: center;
  transition: all 0.3s ease-out;
  width: 52px;
  flex-shrink: 0;

  :not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.primary};
  }

  ${mdUp} {
    height: 60px;
    width: 60px;
  }
`;

export const Menu = styled.div`
  grid-area: menu;
  margin-top: 30px;
  min-height: 130px;

  ${mdUp} {
    margin: 0;
  }
`;

export const MenuItems = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 14px;
  height: 110px;
  max-width: 325px;

  a {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }

  li {
    margin-bottom: 16px;
  }
`;

export const Newsletter = styled.div`
  grid-area: news;
  margin-top: 12px;
  min-height: 130px;

  ${mdUp} {
    margin-top: 30px;
  }

  ${lgUp} {
    margin: 0;
  }
`;

export const Form = styled.form`
  margin-top: 12px;
  max-width: 470px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  padding-right: 76px;
  position: relative;
  margin-bottom: 8px;
`;

export const SubmitBtn = styled.button`
  background-color: ${({ theme }) => theme.black};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  top: 8px;
  color: ${({ theme }) => theme.white};
  display: grid;
  height: 64px;
  place-items: center;
  position: absolute;
  right: 0;
  width: 82px;
`;

export const Copyrights = styled.div`
  border-top: 1px solid ${({ theme }) => theme.background};
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CopyrightsContent = styled.div`
  font-size: 12px;
  padding: 24px 0;

  p:first-child {
    margin-bottom: 12px;
  }

  ${mdUp} {
    align-items: center;
    display: flex;

    p:first-child {
      flex-grow: 1;
      margin-bottom: 0;
      margin-right: 50px;
      white-space: nowrap;
    }
  }
`;
