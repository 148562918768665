import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";

export const H1 = styled.h1`
  color: ${({ theme, light }) => (light ? theme.white : theme.textDark)};
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;

  span {
    font-weight: bold;
  }

  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}

  ${mdUp} {
    font-size: 50px;
    line-height: 58px;
  }

  ${lgUp} {
    font-size: 58px;
    line-height: 68px;
  }
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.textDark};
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 8px;

  ${mdUp} {
    font-size: 22px;
    line-height: 33px;
  }
`;

export const Subheading = styled.span`
  color: ${({ theme }) => theme.primary};
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
`;

export const Paragraph = styled.p`
  line-height: 24px;
  text-align: ${({ center }) => center && "center"};
  font-weight: 400;

  ${mdUp} {
    line-height: 26px;
  }

  span {
    color: ${({ theme }) => theme.textDark};
    font-weight: 500;
  }
`;
