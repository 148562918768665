import { useEffect, useState } from "react";

export const useReferredBy = () => {
  const [referredBy, setReferredBy] = useState("");
  useEffect(() => {
    const url = new URL(location.href);
    const urlRef = url.searchParams.get("r");
    if (urlRef) {
      setReferredBy(urlRef);
    }
  }, []);

  return referredBy;
};
