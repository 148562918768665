import React from "react";

import * as Styled from "./PhotosSection.styles";
import {
  InnerContainer,
  OuterContainer,
} from "src/components/Layout/Layout.styles";
import Stroke1 from "src/assets/svgs/stroke1.svg";
import Stroke1Y from "src/assets/svgs/stroke1--yellow.svg";
import photo1 from "src/assets/images/photos-1.png";
import photo2 from "src/assets/images/photos-2.png";
import photo3 from "src/assets/images/photos-3.png";
import photo4 from "src/assets/images/photos-4.png";

const PHOTOS = [
  {
    img: photo1,
    alt: "India",
  },
  {
    img: photo2,
    alt: "Somalia",
  },
  {
    img: photo3,
    alt: "Madagascar",
  },
  {
    img: photo4,
    alt: "Madagascar",
  },
];

const PhotosSection = () => (
  <OuterContainer bgColor="background" style={{ overflow: "hidden" }}>
    <InnerContainer>
      <Styled.Content>
        {PHOTOS.map(({ img, alt }, i) => (
          <Styled.ImgRelativeWrapper key={i}>
            {i === 1 && <Stroke1 className="stroke1" />}
            {i === 2 && <Stroke1Y className="stroke1y" />}
            <Styled.ImgWrapper>
              <img src={img} alt={alt} />
              <Styled.Gradient />
            </Styled.ImgWrapper>
          </Styled.ImgRelativeWrapper>
        ))}
      </Styled.Content>
    </InnerContainer>
  </OuterContainer>
);

export default PhotosSection;
