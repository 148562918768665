import React, { useState } from "react";
import PropTypes from "prop-types";

import * as Styled from "./Checkbox.styles";
import Check from "src/assets/svgs/check.svg";

const Checkbox = ({ id, name, label, setChecked, checked }) => {
  return (
    <Styled.CheckboxWrapper>
      <Styled.CheckLabel>
        <Styled.CheckInput
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <Styled.Checkmark checked={checked}>
          {checked && <Check />}
        </Styled.Checkmark>
        {label}
      </Styled.CheckLabel>
    </Styled.CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default Checkbox;
