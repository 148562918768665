import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import * as Styled from "./ChangeWorldSection.styles";
import { H1, H2, Paragraph, Button, Subheading } from "src/components/common";
import {
  InnerContainer,
  OuterContainer,
} from "src/components/Layout/Layout.styles";
import Safe from "src/assets/svgs/safe.svg";
import Free from "src/assets/svgs/free.svg";
import Charities from "src/assets/svgs/charities.svg";
import Opensource from "src/assets/svgs/opensource.svg";
import Enterprise from "src/assets/svgs/enterprise.svg";
import Stroke1Y from "src/assets/svgs/stroke1--yellow.svg";
import HeartD from "src/assets/svgs/heart--dark.svg";
import { LinkButton } from "../LinkButton/LinkButton";

const FEATURES = [
  {
    icon: <Safe />,
    title: "100% safe",
    desc:
      "Altruisto does not collect any user data nor it tracks your purchases. We do get information about what kind of products are purchased, but we do not track individual users' purchases. See our <a href='https://altruisto.com/privacy-policy.html' rel='noopener noreferrer' target='_blank'>Privacy Policy</a>",
  },
  {
    icon: <Free />,
    title: "100% free",
    desc:
      "Altruisto is absolutely free to use. The prices in the online stores are exactly the same whether you use altruisto or not. Our partners cover <span>2-6% of the sum</span> you spend in their store.",
  },
  {
    icon: <Charities />,
    title: "Charities we support",
    desc:
      "You can support the fight against extreme poverty, Against Malaria Foundation, Schistosomiasis Control Initiative, and Give Directly. You can also support the fight against animal suffering and more.",
  },
  {
    icon: <Opensource />,
    title: "Open source",
    desc:
      "The source code of our extension is <span>available for everyone to view, audit, and improve</span>. That way everyone can make sure that it is safe to use and does what it is supposed to. See source code",
  },
  {
    icon: <Enterprise />,
    title: "Social enterprise",
    desc:
      "We are registered as <span>Community Interest Company</span>. From Wikipedia: A community interest company (CIC) is a type of company introduced by the United Kingdom government in 2005 (...) designed for social enterprises that want to use their profits and assets for the public good.",
  },
];

const ChangeWorldSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <OuterContainer bgColor="background" id="why-us">
        <InnerContainer>
          <Styled.Content>
            <HeartD className="heart-big" />
            <HeartD className="heart-small" />
            <Styled.TextContent>
              <Subheading>WHY US</Subheading>
              <H1>
                <span>Change the world</span> with Altruisto
              </H1>
              <Paragraph>
                <span>Our mission is simple</span> - we want to engage as many
                people to be altruists as possible. Below you can find out about
                our altruistic plans...
              </Paragraph>
            </Styled.TextContent>
          </Styled.Content>
        </InnerContainer>
      </OuterContainer>
      <OuterContainer bgColor="background" style={{ overflow: "hidden" }}>
        <InnerContainer>
          <Styled.FeaturesMobile>
            <Stroke1Y />
            {FEATURES.map((e, i) => (
              <Styled.Feature key={i}>
                {e.icon}
                <H2>{e.title}</H2>
                <Paragraph
                  center
                  dangerouslySetInnerHTML={{ __html: e.desc }}
                />
              </Styled.Feature>
            ))}
          </Styled.FeaturesMobile>
          <Styled.RelativeContainer>
            <Stroke1Y />
            <Styled.FeaturesDesktop>
              <Slider {...settings}>
                {FEATURES.map((e, i) => (
                  <Styled.Feature key={i}>
                    {e.icon}
                    <H2>{e.title}</H2>
                    <Paragraph
                      center
                      dangerouslySetInnerHTML={{ __html: e.desc }}
                    />
                  </Styled.Feature>
                ))}
              </Slider>
            </Styled.FeaturesDesktop>
          </Styled.RelativeContainer>
          <Styled.ButtonWrapper>
            <LinkButton />
          </Styled.ButtonWrapper>
        </InnerContainer>
      </OuterContainer>
    </>
  );
};

export default ChangeWorldSection;
