import styled, { keyframes } from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";

export const Content = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px 0 186px;
  width: 100%;
  position: relative;

  ${mdUp} {
    padding: 120px 0 180px;
  }

  ${lgUp} {
    padding: 155px 0 292px;
  }

  > svg {
    position: absolute;
    height: auto;
  }

  .heart-small {
    width: 30px;
    top: 20px;
    left: -10px;

    ${mdUp} {
      width: 33px;
      top: 15px;
      left: -25px;
    }

    ${lgUp} {
      display: none;
    }
  }

  .heart-big {
    width: 55px;
    top: -24px;
    left: 50px;
    transform: rotate(35deg);

    ${mdUp} {
      width: 61px;
      top: -30px;
    }

    ${lgUp} {
      display: none;
    }
  }
`;

export const Steps = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;

  ${mdUp} {
    margin-bottom: 50px;
  }

  ${lgUp} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Step = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 360px;
  padding: 0 16px;

  ${mdUp} {
    padding: 0;
  }

  svg {
    height: 200px;
    margin-bottom: 14px;
    width: 100%;

    ${mdUp} {
      height: 255px;
    }
  }
`;

export const Circle = styled.div`
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.textDark};
  display: grid;
  flex-shrink: 0;
  height: 52px;
  margin: 28px 0;
  overflow: hidden;
  place-items: center;
  width: 52px;

  ${mdUp} {
    height: 68px;
    margin: 35px 0;
    width: 68px;
  }

  ${lgUp} {
    margin: 76px -16px 0;

    svg {
      transform: rotate(-90deg);
    }
  }
`;
