import React, { useState } from "react";
import PropTypes from "prop-types";

import * as Styled from "./Select.styles";
import ChevronBtnDark from "src/assets/images/chevron-btn--dark.svg";
import ChevronBtnLight from "src/assets/images/chevron-btn--light.svg";

const Select = ({ label, id, options, dark, onSelect, selected }) => {
  return (
    <Styled.Wrapper>
      <Styled.Label htmlFor={id}>{label}</Styled.Label>
      <Styled.Select
        dark={dark}
        name={id}
        id={id}
        arrowUrl={dark ? ChevronBtnDark : ChevronBtnLight}
        onChange={(e) => {
          onSelect(e.currentTarget.value);
        }}
      >
        {options.map((option, i) => (
          <option key={i} value={option} selected={option === selected}>
            {option}
          </option>
        ))}
      </Styled.Select>
    </Styled.Wrapper>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default Select;
