import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";
import { Paragraph } from "src/components/common";
import MapImg from "src/assets/images/map.svg";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
  margin: 0 -24px;
  overflow: scroll;
  padding-left: 24px;
  position: relative;
  width: calc(100% + 48px);

  ${mdUp} {
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  ::after {
    background: linear-gradient(to right, transparent, #f4204e, transparent);
    bottom: 0;
    content: "";
    height: 1px;
    left: -20px;
    position: absolute;
    width: 100%;
    width: calc(100% + 40px);
  }

  //hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const Tab = styled.button`
  background: transparent;
  border-bottom: 2px solid transparent;
  color: ${({ theme }) => theme.text};
  font-size: 15px;
  margin-right: 10px;
  padding: 15px 10px;
  white-space: nowrap;

  ${({ selected, theme }) =>
    selected &&
    `
    border-bottom: 2px solid ${theme.primary};
    color: ${theme.primary};
  `}
`;

export const TabContent = styled.div`
  padding: 180px 16px 0;
  position: relative;

  ${mdUp} {
    padding: 160px 80px 0;
  }

  ${lgUp} {
    padding: 200px 100px 0;
  }
`;

export const Count = styled.div`
  color: ${({ theme }) => theme.textDark};
  font-family: "Playfair Display", serif;
  font-size: 80px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;

  ${mdUp} {
    font-size: 110px;
    margin-bottom: 30px;
  }

  span {
    color: ${({ theme }) => theme.text};
    font-size: 24px;
  }
`;

export const Description = styled(Paragraph)`
  display: block;
  margin: 0 auto;
  max-width: 710px;
  text-align: center;
`;

export const Map = styled.div`
  /* TODO: add white gradient; */
  background-image: url(${MapImg});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 400px;
  left: calc(-12% - 40px);
  position: absolute;
  top: 0;
  width: calc(125% + 80px);
  z-index: -2;

  ${mdUp} {
    height: 450px;
    left: -40px;
    top: -83px;
    width: calc(100% + 80px);
  }

  ${lgUp} {
    height: 700px;
    top: -98px;
  }
`;

export const Markers = styled.div`
  background-image: ${({ url }) => `url(${url})`};
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: ${({ id, tabIndex }) => (id === tabIndex ? "1" : "0")};
  position: absolute;
  top: 0;
  transition: all 0.4s ease-out;
  width: 100%;
`;

export const Gradient = styled.div`
  background-image: radial-gradient(
    100% 871.27% at 0% 100%,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 50.09%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  width: 100%;
  z-index: -1;
`;
