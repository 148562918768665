import { links } from "src/constants/constants";

export const FAQS = [
  {
    q: "How does it work?",
    a:
      "In short, after installing Altruisto, when you visit one of our partner stores you will see a special box with a button saying “Activate donation.” When you click this button, you will be redirected back to the partner store (exactly where you were before). When you buy something, Altruisto receives a portion of the price you pay (usually between 2% and 6%). We donate our profits to carefully selected charities. Some stores will require you to repeat the process after some time.",
  },
  {
    q: "Does it cost me anything to use Altruisto?",
    a:
      "No, Altruisto is absolutely free to use. The prices in the online stores are exactly the same whether you use altruisto or not.",
  },
  {
    q: "How can I be sure that the money will be donated to charities?",
    a:
      "Altruisto is set up as Community Interest Company.<br /><br />From Wikipedia:<br /><i>A community interest company (CIC) is a type of company introduced by the United Kingdom government in 2005 under the Companies (Audit, Investigations and Community Enterprise) Act 2004, designed for social enterprises that want to use their profits and assets for the public good. CICs are intended to be easy to set up, with all the flexibility and certainty of the company form, but with some special features to ensure they are working for the benefit of the community.</i>",
  },
  {
    q: "What percentage of the purchase is donated to charities?",
    a:
      "Altruisto receives usually 2-6% of the final purchase price. The percentage depends on the shop and the particular product.",
  },
  {
    q: "What stores does Altruisto work with?",
    a: `We have partnered with several big e-commerce businesses such as Ebay, Microsoft, Booking.com, Aliexpress, Etsy, Barnes&Noble, and over 1000 others. A full list of partner stores can be found at: <a href=${links.partners} rel='noopener noreferrer' target='_blank'>https://altruisto.com/partners</a>.`,
  },
  {
    q: "What charities do you support and why these and not others?",
    a:
      "We have several cause areas you can choose from when you create an account. You can support the fight against extreme poverty, with Against Malaria Foundation, Schistosomiasis Control Initiative, and Give Directly. You can also support the fight against animal suffering, with Anima International.<br /><br />The charities we've chosen to support have been recommended by either GiveWell or Animal Charity Evaluators, organizations that do in-depth research into how well charities do what they are supposed to do and chooses the best ones. As a result, the charities we support are exceptional at improving the lives of people living in extreme poverty or reducing animal suffering.<br /><br />Selected charities are evidence-backed, thoroughly vetted, and underfunded.<br /><br />Therefore, you can be sure the money they receive from your online shopping is used to do as much good as possible. Also, because the charities are so efficient with their funds, every small purchase in altruisto's partners' shops makes a real difference.",
  },
  {
    q:
      "I am not buying in any of these stores, is there any reason for me to install Altruisto?",
    a:
      "Yes, there are three reasons:<br /><br /><ol><li>We are constantly working on new partnerships, so we may partner with stores that you do use in the near future. It’s easier to have it already installed rather than regularly check if stores that you use have been added.</li><li>If you even once decide to buy from our partners stores, having Altruisto already installed can be a difference of curing one child from parasitic worms or protecting a couple people from malaria. It’s better to have it installed just in case, rather than miss an easy opportunity to do a lot of good for free.</li><li>It is easier to establish new partnerships with a bigger userbase: the more users Altruisto has, the easier it is to convince new stores to join our initiative. Therefore, by installing Altruisto, you make it more probable that we will partner with shops you use in the future.</li></ol>",
  },
  {
    q:
      "I only shop at Amazon and already use Amazon Smile program, is there any reason for me to install Altruisto?",
    a:
      "First of all, <span>our partners usually donate 4-12 times more</span> through Altruisto (2-6%) than Amazon does through Amazon Smile (only 0.5% of the final price). So, if you care about having as big a positive impact on the world as you can, using Altruisto either instead of or in addition to Amazon Smile is a way to achieve that.<br /><br />Moreover, there are a lot of products or services available in our partner stores that are not available on Amazon. For example, travel services (hotels, flights, train tickets, car rentals, financial products, or sport clubs’ official apparel.",
  },
  {
    q: "How do shops benefit from being in Altruisto program?",
    a:
      "<ol><li>Our users are clients who have a strong incentive to buy from our partner shops and not their competition.</li><li>We are preparing multiple new features such as highlighting partners' websites in search results or informing the user when the product they are going to buy is available for the same price in one of our partners' stores. These features directly drive new consumers to partners' stores.</li><li>Last, but definitely not least, thanks to Altruisto, our partners' brands are associated with a great cause.</li></ol>",
  },
  {
    q: "Does Altruisto track my purchases or internet activity?",
    a:
      "No, we do not. We do get information about what kind of products are purchased and how many items our users bought each month, but we do not track individual users' purchases. You can find out more in our <a href='https://altruisto.com/privacy-policy.html' rel='noopener noreferrer' target='_blank'>Privacy Policy</a> (we’ve tried to keep it simple and easy to understand).",
  },
  {
    q:
      "Why installing Altruisto requires permission to “Read and change all data on websites you visit”?",
    a:
      "For the extension to work properly it needs to have access to the information about what website you have opened to know if you're on one of our partners' sites (hence the \"read data on websites\" part) and be able to add new elements to the site (i.e. a box that displays a button for raising money. Hence the \"change data on websites\" part). In short, the extension downloads the list of partner shops, then works in the background, comparing the website you're viewing against the downloaded list, and if there is a match, it shows a special button. <span>The information about visited websites does not leave your computer, so your privacy is protected</span>.<br /><br />Altruisto has an open source code available at <a href='https://github.com/Altruisto/altruisto' rel='noopener noreferrer' target='_blank'>Github</a> so anyone can view and audit it to make sure it is safe to use.",
  },
];
