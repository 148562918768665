import React from "react";

import {
  OuterContainer,
  InnerContainer,
} from "src/components/Layout/Layout.styles";
import { H1, Subheading, Paragraph } from "src/components/common";
import { Calculator } from "src/components";
import * as Styled from "./CalculatorSection.styles";

const CalculatorSection = () => (
  <OuterContainer bgColor="background">
    <InnerContainer>
      <Styled.Content>
        <Styled.TextContent>
          <Subheading>SOLUTION</Subheading>
          <H1>
            It’s never been easier for you to <span>change that!</span>
          </H1>
          <Paragraph center>
            All it takes for you is to <span>install our plugin</span> and
            activate it when shopping in one of our partners’ websites.{" "}
            <span>No additional expenses required</span> - simply buy anything
            and our partners will pass part of the money to charity of your
            selection.
          </Paragraph>
        </Styled.TextContent>
        <Calculator />
      </Styled.Content>
    </InnerContainer>
  </OuterContainer>
);

export default CalculatorSection;
