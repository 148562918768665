import styled from "styled-components";

import { ButtonPrimary } from "src/components/common/Button/Button.styles";
import { mdUp, lgUp, xlUp } from "src/styles/breakpoints";

export const Wrapper = styled.div`
  width: 100%;

  ${lgUp} {
    display: flex;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 12px;
  padding: 32px 24px 40px;
  width: 100%;
  position: relative;

  ${mdUp} {
    padding: 46px 40px 48px;
  }

  ${lgUp} {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 60%;
  }

  .heart {
    position: absolute;
    top: -45px;
    right: 20px;
    width: 40px;
    height: auto;
    transform: scaleX(-1);

    ${mdUp} {
      right: 40px;
    }

    ${lgUp} {
      right: -20px;
      top: -80px;
    }

    ${xlUp} {
      right: -80px;
    }
  }

  .stroke {
    display: none;

    ${lgUp} {
      position: absolute;
      display: block;
      right: -20px;
      transform: scaleX(-1);
      z-index: -1;
      bottom: -80px;
    }

    ${xlUp} {
      right: -80px;
      bottom: -55px;
    }
  }
`;

export const ImageBox = styled.div`
  display: none;

  ${lgUp} {
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    display: block;
    position: relative;
    width: 40%;
    background: linear-gradient(180deg, #efeceb 0%, #e8e4e3 100%),
      linear-gradient(
        180deg,
        rgba(196, 196, 196, 0) 0%,
        rgba(253, 56, 66, 0.3) 100%
      );
  }

  img {
    left: -10%;
    margin: -10%;
    max-width: 130%;
    position: relative;
    right: -10%;
    top: -5%;
    z-index: 1;
  }
`;

export const Form = styled.form`
  margin: 22px 0 26px;
  width: 100%;

  ${mdUp} {
    display: flex;
    justify-content: space-between;
    margin: 32px 0 30px;

    > div {
      width: calc(50% - 10px);
    }
  }
`;

export const InputWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: 14px;
  max-width: 400px;

  ${mdUp} {
    margin-top: 0;
    width: 50%;
  }

  > button {
    flex-shrink: 0;
    margin-left: 16px;
  }
`;

export const SubmitButton = styled(ButtonPrimary)`
  width: 64px !important;

  svg {
    transform: rotate(-90deg);
  }
`;

export const H3 = styled.h3`
  color: ${({ theme }) => theme.textDark};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;

  ${mdUp} {
    margin-bottom: 24px;
  }
`;

export const Benefits = styled.div`
  width: 100%;

  ${mdUp} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      width: calc(50% - 10px);
    }
  }
`;

export const Benefit = styled.div`
  align-items: center;
  display: flex;

  :not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.background};
  border-radius: 50%;
  display: grid;
  flex-shrink: 0;
  height: 60px;
  margin-right: 18px;
  place-items: center;
  width: 60px;

  ${mdUp} {
    height: 70px;
    width: 70px;
  }
`;

export const Desc = styled.div`
  span {
    display: block;
  }

  .title {
    color: ${({ theme }) => theme.textDark};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 4px;
  }

  .desc {
    font-size: 13px;
    line-height: 18px;

    ${mdUp} {
      font-size: 14px;
    }
  }
`;
