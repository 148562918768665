import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";

export const Content = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 22px 0 34px;

  ${mdUp} {
    padding: 30px 0 60px;
  }
`;

export const Heading = styled.h1`
  color: ${({ theme }) => theme.textAlt};
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline;

  ${mdUp} {
    font-size: 14px;
    margin-bottom: 16px;
  }

  ${lgUp} {
    margin-bottom: 22px;
  }
`;

export const Partners = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 300px;

  img {
    margin-top: 20px;

    :nth-child(odd) {
      margin-right: 30px;
    }
  }

  ${mdUp} {
    justify-content: space-between;
    width: 400px;

    img {
      margin-top: 0;

      :nth-child(odd) {
        margin-right: 0px;
      }

      :nth-child(n + 4) {
        margin-top: 36px;
      }
    }
  }

  ${lgUp} {
    width: 880px;

    img {
      margin: 0 !important;
    }
  }
`;
