import React, { useState } from "react";

import * as Styled from "./ProblemsTabs.styled";
import MarkersAnimal from "src/assets/images/markers-animal.svg";
import MarkersCovid from "src/assets/images/markers-covid.svg";
import MarkersMalaria from "src/assets/images/markers-malaria.svg";
import MarkersWater from "src/assets/images/markers-water.svg";
import MarkersPoverty from "src/assets/images/markers-poverty.svg";

const PROBLEMS = [
  {
    name: "Extreme poverty",
    description:
      "Around 690 million people, or nearly <span>10% of the world's population</span>, lives in extreme poverty. Nearly half of this group are children under the age of 14.<br />Although <span>global poverty is still a burning issue</span> and a lot of work needs to be done, it’s not a lost cause! The only way to reduce poverty and change the lives of the millions of people suffering from it is cooperation. Global citizens, charities, and governments have to join forces against the war on poverty.",
    count: "690 <span>mln</span>",
    markersUrl: MarkersPoverty,
  },
  {
    name: "COVID-19",
    description:
      "There are over 210 million confirmed cases of COVID-19 and almost <span>4.5 million people have died</span>. As restrictions ease in the global north – infections are soaring for millions of the world’s poorest people – with no proper vaccination programmes in sight. Good hygiene practices, especially handwashing, are the primary shield against the Coronavirus. Unfortunately, there are places in the world, where soap and water are a luxury. <span>Globally, 40% of people lack access to a handwashing facility.</span> It’s even worse in Sub-Saharan Africa, where 75% of people lack access to it.",
    count: "210 <span>mln</span>",
    markersUrl: MarkersCovid,
  },
  {
    name: "Global water crisis",
    description:
      "The water crisis is massive. <span>785 million people lack basic access to clean and safe drinking water.</span> However if we work together, there is a good chance that everyone will have access to life’s most basic need within our lifetime.",
    count: "785 <span>mln</span>",
    markersUrl: MarkersWater,
  },
  {
    name: "Malaria",
    description:
      "Malaria causes over 400 000 deaths each year. <span>Children under 5 years old are the most affected,</span> accounting for 67% of malaria deaths worldwide. But preventing the spread of malaria with LLINs - long-lasting insecticidal nets. One net can be delivered to people who are most vulnerable for just $4.85. That's as good a deal as it gets.",
    count: "400 000 <span>deaths each year</span>",
    markersUrl: MarkersMalaria,
  },
  {
    name: "Animal suffering",
    description:
      "130 billion animals suffer every day on factory farms. They are raised and slaughtered in conditions causing extreme distress. For example, broiler chickens are often packed up to 19 chickens in a square metre. This is a little less than an A4 page for one bird.",
    count: "130 <span>billion</span>",
    markersUrl: MarkersAnimal,
  },
];

const ProblemsTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Styled.Wrapper>
      <Styled.Tabs>
        {PROBLEMS.map(({ name }, i) => (
          <Styled.Tab
            key={name}
            onClick={() => setTabIndex(i)}
            selected={tabIndex === i}
          >
            {name}
          </Styled.Tab>
        ))}
      </Styled.Tabs>
      <Styled.TabContent>
        <Styled.Gradient />
        <Styled.Map>
          {PROBLEMS.map(({ markersUrl, name }, i) => (
            <Styled.Markers
              url={markersUrl}
              id={i}
              tabIndex={tabIndex}
              key={name}
            />
          ))}
        </Styled.Map>
        <Styled.Count
          dangerouslySetInnerHTML={{ __html: PROBLEMS[tabIndex].count }}
        />
        <Styled.Description
          dangerouslySetInnerHTML={{ __html: PROBLEMS[tabIndex].description }}
        />
      </Styled.TabContent>
    </Styled.Wrapper>
  );
};

export default ProblemsTabs;
