import React from "react";
import PropTypes from "prop-types";

import * as Styled from "./Layout.styles";
import { Header, Footer } from "src/components";

const Layout = ({ children, observerInView }) => (
  <Styled.Wrapper>
    <Header observerInView={observerInView} />
    {children}
    <Footer />
  </Styled.Wrapper>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
