import styled from "styled-components";

import { mdUp } from "src/styles/breakpoints";
import { Paragraph } from "src/components/common/Typography/index.js";

export const Accordion = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 12px;
  padding: 14px 14px 14px 24px;
  transition: all 0.3s ease-in;
  position: relative;

  :not(:last-child) {
    margin-bottom: 14px;
  }

  ${mdUp} {
    padding-left: 40px;
  }
`;

export const Question = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.textDark};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 22.5px;
`;

export const IconWrapper = styled.button`
  background-color: ${({ theme }) => theme.button.secondaryDisabled};
  border-radius: 8px;
  color: ${({ theme }) => theme.primary};
  display: grid;
  flex-shrink: 0;
  height: 42px;
  margin-left: 18px;
  place-items: center;
  transition: all 0.2s ease-in;
  width: 42px;

  svg {
    transition: all 0.2s ease-in;

    ${({ answerOpen }) =>
      answerOpen &&
      `
    transform: rotate(180deg);
    `}
  }

  ${mdUp} {
    height: 46px;
    width: 46px;
  }
`;

export const Answer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease-in;

  ${({ answerOpen }) =>
    answerOpen &&
    `
    max-height: 700px;
  `}

  ${mdUp} {
    padding-right: 78px;
  }
`;

export const AnswerText = styled(Paragraph)`
  font-size: 15px;
  padding-top: 24px;

  ol {
    list-style: decimal;
    padding-left: 14px;
  }

  li:not(:last-of-type) {
    margin-bottom: 16px;
  }

  a {
    color: ${({ theme }) => theme.textDark};
    text-decoration: underline;
  }
`;
