import React, { useState } from "react";

import * as Styled from "./Calculator.styles";
import { H2, Paragraph, TextInput, Select } from "src/components/common";
import Chevron from "src/assets/svgs/chevron.svg";
import Hygiene from "src/assets/svgs/hygiene.svg";
import Soap from "src/assets/svgs/soap.svg";
import Water from "src/assets/svgs/water.svg";
import Parasites from "src/assets/svgs/parasites.svg";
import Financial from "src/assets/svgs/financial.svg";
import Malaria from "src/assets/svgs/malaria.svg";
import africa from "src/assets/images/africa.png";
import HeartD from "src/assets/svgs/heart--dark.svg";
import Stroke2 from "src/assets/svgs/stroke2.svg";

const BENEFITS = {
  covid: [
    {
      icon: <Soap />,
      count: "families",
      text: "get 1 mth supply of soap",
      key: "soap",
    },
    {
      icon: <Water />,
      count: "families",
      text: "get 1 mth supply of clean, safe  water",
      key: "water",
    },
    {
      icon: <Hygiene />,
      count: "families",
      text: "get 1 mth supply of life-saving hygiene kits",
      key: "hygiene",
    },
  ],
  poverty: [
    {
      icon: <Parasites />,
      count: "children",
      text: "Get cured from parasites",
      key: "SCI",
    },
    {
      icon: <Malaria />,
      count: "people",
      text: "Get protected from malaria",
      key: "AMF",
    },
    {
      icon: <Financial />,
      count: "families",
      text: "1 week of financial aid",
      key: "GD",
    },
  ],
};

const IMPACT_COST_IN_USD_FOR_POVERTY = {
  SCI: 1.19, // one child cured from parasites
  AMF: 2.43, // one person protected from malaria
  GD: 21.13, // one week of financial help for a family in extreme poverty
};

const IMPACT_COST_IN_USD_FOR_COVID = {
  soap: 0.62,
  water: 6.22,
  hygiene: 21,
};

const spreadUSDPoverty = (money) => {
  let moneyLeft = money;
  const cheapestImpact = IMPACT_COST_IN_USD_FOR_POVERTY.SCI;
  // Object.values(IMPACT_COST_IN_USD).sort((a,b) => a - b)[0]
  let result = {
    SCI: 0,
    AMF: 0,
    GD: 0,
  };

  const buy = (charity) => {
    moneyLeft -= IMPACT_COST_IN_USD_FOR_POVERTY[charity];
    result[charity] += 1;
  };

  while (moneyLeft >= cheapestImpact) {
    buy("SCI");
    if (moneyLeft >= IMPACT_COST_IN_USD_FOR_POVERTY["AMF"]) {
      buy("AMF");
    } else {
      continue;
    }
    if (moneyLeft >= IMPACT_COST_IN_USD_FOR_POVERTY["GD"]) {
      buy("GD");
    } else {
      continue;
    }
  }

  return [result, moneyLeft];
};

const spreadUSDCovid = (money) => {
  let moneyLeft = money;
  const cheapestImpact = IMPACT_COST_IN_USD_FOR_COVID.soap;
  // Object.values(IMPACT_COST_IN_USD).sort((a,b) => a - b)[0]
  let result = {
    soap: 0,
    water: 0,
    hygiene: 0,
  };

  const buy = (goal) => {
    moneyLeft -= IMPACT_COST_IN_USD_FOR_COVID[goal];
    result[goal] += 1;
  };

  while (moneyLeft >= cheapestImpact) {
    buy("soap");
    if (moneyLeft >= IMPACT_COST_IN_USD_FOR_COVID["water"]) {
      buy("water");
    } else {
      continue;
    }
    if (moneyLeft >= IMPACT_COST_IN_USD_FOR_COVID["hygiene"]) {
      buy("hygiene");
    } else {
      continue;
    }
  }

  return [result, moneyLeft];
};

const distributeAmountToCause = (amount, cause) => {
  if (cause === "COVID-19") {
    const [spreadingResult, _] = spreadUSDCovid(amount);
    return spreadingResult;
  }

  if (cause === "Poverty") {
    const [spreadingResult, _] = spreadUSDPoverty(amount);
    return spreadingResult;
  }
};

const Calculator = () => {
  const DONATION_PERCENTAGE = 0.06;
  const [amount, setAmount] = useState("200");
  const [cause, setCause] = useState("Poverty");

  const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const handleAmountChange = (newValue) => {
    if (!newValue || isNumeric(newValue)) {
      setAmount(newValue);
    }
  };

  return (
    <Styled.Wrapper id="solution">
      <Styled.ImageBox>
        <img src={africa} alt="African children" />
      </Styled.ImageBox>
      <Styled.Container>
        <HeartD className="heart" />
        <Stroke2 className="stroke" />
        <H2>See what difference you can make</H2>
        <Paragraph>
          Usually, most partners share 2-6% of the price with the charities we
          support.
          {/* TODO: add link */}
          {/* See calculations. */}
        </Paragraph>
        <Styled.Form>
          <Select
            label="You fight against"
            id="cause"
            dark
            options={["COVID-19", "Animals suffering", "Poverty"]}
            selected={cause}
            onSelect={setCause}
          />
          <Styled.InputWrapper>
            <TextInput
              label="You shop for $"
              id="amount"
              placeholder="eg. 100"
              dark
              value={amount}
              setValue={handleAmountChange}
            />
            <Styled.SubmitButton>
              <Chevron />
            </Styled.SubmitButton>
          </Styled.InputWrapper>
        </Styled.Form>
        <Styled.H3>
          ${amount * DONATION_PERCENTAGE} donated from shopping for ${amount}{" "}
        </Styled.H3>
        <Styled.Benefits>
          <DistributionResult
            spreadingResult={distributeAmountToCause(
              amount * DONATION_PERCENTAGE,
              cause
            )}
            cause={cause}
          />
        </Styled.Benefits>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

const DistributionResult = ({ spreadingResult, cause }) => {
  if (cause === "COVID-19") {
    return BENEFITS.covid.map((benefit, i) =>
      spreadingResult[benefit.key] ? (
        <Styled.Benefit key={i}>
          <Styled.Circle>{benefit.icon}</Styled.Circle>
          <Styled.Desc>
            <span className="title">
              {spreadingResult[benefit.key]} {benefit.count}
            </span>
            <span className="desc">{benefit.text}</span>
          </Styled.Desc>
        </Styled.Benefit>
      ) : null
    );
  }

  if (cause === "Poverty") {
    return BENEFITS.poverty.map((benefit, i) =>
      spreadingResult[benefit.key] ? (
        <Styled.Benefit key={i}>
          <Styled.Circle>{benefit.icon}</Styled.Circle>
          <Styled.Desc>
            <span className="title">
              {spreadingResult[benefit.key]} {benefit.count}
            </span>
            <span className="desc">{benefit.text}</span>
          </Styled.Desc>
        </Styled.Benefit>
      ) : null
    );
  }

  if (cause === "Animals suffering") {
    return (
      <>
        <Styled.Benefit>
          <Styled.Circle>
            <Financial />
          </Styled.Circle>
          <Styled.Desc>
            <span className="title">Anima International</span>
            <span className="desc">will receive the donation</span>
          </Styled.Desc>
        </Styled.Benefit>
        <span className="desc">
          For various reasons the evaluators of charities working towards
          reduction of animal suffering decided to refrain from calculating how
          many animals can be spared for a dollar donated.
        </span>
      </>
    );
  }

  return null;
};

export default Calculator;
