import styled, { keyframes } from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";
import { OuterContainer } from "src/components/Layout/Layout.styles";

export const CustomOuterContainer = styled(OuterContainer)`
  background-color: ${({ theme }) => theme.secondary};
  padding-top: 92px;
  position: relative;
  z-index: 11;
`;

export const IntersectionObserver = styled.span`
  width: 0;
  height: 0;
  position: absolute;
  top: 70px;
`;

export const BgImg = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  background-image: ${({ url }) => `url(${url.mobile})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  opacity: ${({ id, currentProblemId }) =>
    id === currentProblemId ? "1" : "0"};
  position: absolute;
  top: 0;
  transition: all 1s ease-in;
  width: 100%;
  transform: translateZ(0);

  ${lgUp} {
    background-image: ${({ url }) => `url(${url.desktop})`};
    background-size: auto 100%;
  }
`;

export const Content = styled.section`
  color: ${({ theme }) => theme.white};
  max-width: 400px;
  padding: 60px 0 96px;
  position: relative;

  ${mdUp} {
    max-width: 500px;
    padding: 68px 0 86px;
  }

  ${lgUp} {
    max-width: 700px;
    padding: 100px 0 112px;
  }
`;

export const TextPlaceholder = styled.div`
  height: 330px;
  position: relative;

  ${mdUp} {
    height: 380px;
    margin-top: 30px;
  }

  ${lgUp} {
    height: 300px;
  }
`;

export const TextWrapper = styled.div`
  height: 100%;
  left: 0;
  opacity: ${({ id, currentProblemId }) =>
    id === currentProblemId ? "1" : "0"};
  position: absolute;
  top: 0;
  transition: all 1s ease-in;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.white};
  display: block;
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 400;
  font-weight: bold;
  line-height: 44px;
  max-width: 360px;

  span {
    display: block;
    font-weight: 400;
    opacity: 0.6;
  }

  ${mdUp} {
    font-size: 58px;
    line-height: 62px;
    max-width: 100%;
  }
`;

export const Subtitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 22px 0 16px;

  ${mdUp} {
    margin: 28px 0 24px;
  }
`;

export const Paragraph = styled.p`
  line-height: 24px;

  ${mdUp} {
    line-height: 26px;
  }

  ${lgUp} {
    max-width: 570px;
  }

  span {
    font-weight: 400;
    opacity: 0.6;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 30px;

  .extension-link {
    display: none;
  }

  ${mdUp} {
    display: flex;

    .extension-link {
      display: grid;
      margin-left: 20px;
    }
  }
`;

export const Gradient1 = styled.div`
  background-image: linear-gradient(
    101.57deg,
    #150f0c 39.02%,
    rgba(21, 15, 12, 0) 80.33%
  );
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const Gradient2 = styled.div`
  background-image: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(253, 56, 66, 0.08) 100%
  );
  bottom: 0;
  height: 80%;
  left: 0;
  position: absolute;
  width: 100%;
`;

const slide = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(100%);
  }
`;

export const ScrollBtn = styled.button`
  display: none;

  ${mdUp} {
    background: transparent;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: ${({ theme }) => theme.white};
    display: grid;
    height: 70px;
    margin-top: 40px;
    place-items: center;
    width: 70px;

    :hover {
      svg {
        animation: ${slide} 0.8s linear infinite;
      }
    }
  }

  ${lgUp} {
    margin-top: 46px;
  }
`;
