import styled from "styled-components";

import { smUp, mdUp, lgUp, xlUp } from "src/styles/breakpoints";
import { InnerContainer } from "src/components/Layout/Layout.styles";
import {
  ButtonBlurred,
  ButtonTertiary,
} from "src/components/common/Button/Button.styles";

export const Wrapper = styled.div`
  background-color: ${({ theme, withBackground }) =>
    withBackground && theme.secondary};
  height: 92px;
  position: relative;
  width: 100%;
  z-index: 999;
  position: fixed;
  transition: background-color 0.3s ease-out;

  ${lgUp} {
    display: none;
  }
`;

export const ContentMobile = styled(InnerContainer)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

export const MenuBtn = styled.button`
  backdrop-filter: blur(15px);
  background: rgba(246, 243, 238, 0.1);
  border-radius: 6px;
  display: grid;
  height: 52px;
  place-items: center;
  width: 52px;
`;

export const MobileMenuWrapper = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  background-image: linear-gradient(
    180deg,
    rgba(21, 15, 12, 0.03) 0%,
    rgba(253, 56, 66, 0.03) 100%
  );
  background-position: center bottom;
  bottom: 1px;
  left: 0;
  padding: 0 24px 0;
  position: absolute;
  transform: translateY(100%);
  width: 100%;
  max-height: ${({ visible }) => (visible ? "100vh" : "0px")};
  overflow: hidden;
  transform: translateY(100%);
  transition: max-height 0.3s ease-in;
  z-index: 2;

  ${mdUp} {
    padding: 0 24px;
  }
`;

export const MobileMenu = styled.ul`
  color: ${({ theme }) => theme.white};
  font-size: 15px;
  padding: 10px 0 20px;

  ${smUp} {
    padding: 10px 0 40px;
  }

  ${mdUp} {
    font-size: 16px;
    margin: 0 auto;
    width: 460px;
    margin-top: 80px;
  }
`;

export const MobileMenuItem = styled.li`
  height: 56px;
  position: relative;
  text-align: center;

  ${smUp} {
    height: 66px;
  }

  span {
    display: grid;
    height: 100%;
    opacity: 0.7;
    place-items: center;
    transition: all 0.2s ease-out;
    width: 100%;

    :hover {
      opacity: 1;
    }
  }

  :not(:last-child) {
    ::after {
      background: linear-gradient(to right, transparent, #d8d1d0, transparent);
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      opacity: 0.2;
      position: absolute;
      width: 100%;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;

  ${smUp} {
    margin-bottom: 50px;
  }

  > a:first-child {
    margin-bottom: 20px;
  }

  ${mdUp} {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 460px;
    margin-bottom: 160px;

    > a:first-child {
      margin: 0 20px 0 0;
    }
  }
`;

export const WrapperDesktop = styled(Wrapper)`
  display: none;

  ${lgUp} {
    display: grid;
    place-items: center;
  }
`;

export const DesktopNav = styled.nav`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 0 24px;
  width: 100%;

  svg {
    margin-right: auto;
    margin-top: -14px;
  }

  ${xlUp} {
    padding: 0 60px;
  }
`;

export const DesktopMenu = styled.ul`
  align-items: center;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-size: 14px;

  li {
    cursor: pointer;
    margin-right: 40px;
    opacity: 0.7;
    transition: all 0.2s ease-out;

    ${xlUp} {
      :last-child {
        margin-right: 160px;
      }
    }

    :hover {
      opacity: 1;
    }
  }
`;

export const BlurredButton = styled(ButtonBlurred)`
  width: 140px;

  ${xlUp} {
    width: 220px;
  }
`;

export const SignupButton = styled(ButtonTertiary)`
  width: 140px;

  ${xlUp} {
    width: 220px;
  }

  ${({ observerInView, theme }) =>
    !observerInView &&
    `
    background: ${theme.gradient.primary};

  :hover {
    background: ${theme.gradient.secondary};
  }
  `}
`;

export const DesktopButtonsWrapper = styled.div`
  align-items: center;
  display: flex;

  button:first-child {
    margin-right: 20px;
  }
`;
