import React from "react";

import * as Styled from "./FaqSection.styles";
import { H1, Paragraph, Subheading, Accordion } from "src/components/common";
import {
  InnerContainer,
  OuterContainer,
} from "src/components/Layout/Layout.styles";
import { FAQS } from "./faqsContent";
import Stroke1 from "src/assets/svgs/stroke1.svg";
import Stroke1Y from "src/assets/svgs/stroke1--yellow.svg";
import HeartD from "src/assets/svgs/heart--dark.svg";

const FaqSection = () => (
  <OuterContainer bgColor="background" id="faq">
    <InnerContainer>
      <Styled.Content>
        <Styled.TextContent>
          <HeartD className="heart-left" />
          <HeartD className="heart-right" />
          <Subheading>FAQ</Subheading>
          <H1>
            Questions & <span>Answers</span>
          </H1>
          <Paragraph>
            Find out more about Altruisto and how our browser extension works.
          </Paragraph>
        </Styled.TextContent>
        <Styled.FaqList>
          <Stroke1Y className="stroke1--y" />
          <Stroke1 className="stroke1" />
          {FAQS.map(({ q, a }, i) => (
            <Accordion question={q} answer={a} key={i} />
          ))}
          <Stroke1Y className="stroke1--2" />
        </Styled.FaqList>
      </Styled.Content>
    </InnerContainer>
  </OuterContainer>
);

export default FaqSection;
