import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";
import { Paragraph } from "src/components/common/Typography/index.js";
import Arrow from "src/assets/images/arrow.svg";

export const Content = styled.section`
  padding: 80px 0;

  ${mdUp} {
    padding: 120px 0;
  }

  ${lgUp} {
    padding: 160px 0;
  }
`;

export const TextContent = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  ${mdUp} {
    text-align: left;
    margin-bottom: 60px;

    h1 {
      text-align: left;
    }

    span {
      display: block;
    }
  }
`;

export const Posts = styled.div`
  width: calc(100% + 14px);
  position: relative;

  .slick-list {
    overflow: visible;
    position: static;
  }

  .slick-slider {
    position: static;
  }

  .slick-arrow {
    display: grid !important;
    font-size: 0;
    height: 12px;
    line-height: 0;
    place-items: center;
    top: auto;
    width: 22px;
    z-index: 2;
    bottom: -3px;
    transform: translateY(100%);

    ::before {
      content: url(${Arrow});
      font-size: 0;
      line-height: 0;
    }

    &.slick-prev {
      left: 0;

      ::before {
        transform: rotate(180deg);
      }
    }

    &.slick-next {
      left: 180px;
    }
  }

  .slick-dots {
    bottom: 0;
    text-align: left;
    margin-left: 40px;
    transform: translateY(100%);

    li {
      margin: 0;
      width: 30px;
      height: 13px;
    }

    button {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 13px;

      ::before {
        background-color: ${({ theme }) => theme.textDark};
        content: "";
        height: 2px;
        width: 100%;
      }
    }
  }
`;

export const Slide = styled.div`
  width: 100%;
  max-width: calc(100% - 14px);

  ${mdUp} {
    max-width: calc(100% - 20px);
  }

  ${mdUp} {
    max-width: calc(100% - 44px);
  }

  .read-more {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  :hover {
    .read-more {
      opacity: 1;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 193px;
  }

  ${lgUp} {
    img {
      height: 215px;
    }
  }
`;

export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(253, 56, 66, 0.1) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.textDark};
  display: block;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 16px 0 12px;
`;

export const Desc = styled(Paragraph)`
  display: block;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const SlideContent = styled.div`
  padding: 24px 20px 30px;
`;

export const ReadMore = styled.div`
  color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  margin-top: 32px;

  span {
    margin-left: 16px;
  }
`;

export const BtnLink = styled.a`
  margin-top: 70px;
  display: block;
`;
