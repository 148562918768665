import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";

export const Content = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  ${mdUp} {
    padding-bottom: 120px;
  }

  ${lgUp} {
    padding-bottom: 160px;
  }
`;

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: -102px 0 45px;
  max-width: 496px;

  h1 {
    margin: 10px 0 26px;
  }

  ${mdUp} {
    margin: -75px 0 70px;
    max-width: 781px;

    h1 {
      margin: 10px 0 40px;
    }
  }

  ${lgUp} {
    margin: -140px 0 86px;
  }
`;
