import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";

export const Content = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px 0 0;

  ${mdUp} {
    padding: 120px 0 0;
  }

  ${lgUp} {
    padding: 190px 0 0;
  }
`;

export const TitleWrapper = styled.div`
  margin: 12px 0;
  width: 275px;
  position: relative;

  ${mdUp} {
    margin: 12px 0 30px;
    width: 550px;
  }

  ${lgUp} {
    width: 700px;
  }

  > svg {
    position: absolute;
    height: auto;
  }

  .heart1 {
    width: 35px;
    top: -10px;
    left: -20px;

    ${mdUp} {
      top: -70px;
      width: 44px;
      transform: rotate(25deg);
      left: -60px;
    }

    ${lgUp} {
      left: -160px;
      width: 50px;
    }
  }

  .heart2 {
    width: 35px;
    top: 65px;
    right: -20px;

    ${mdUp} {
      right: -50px;
    }

    ${lgUp} {
      right: -100px;
      top: 100px;
    }
  }

  .heart3 {
    display: none;

    ${mdUp} {
      display: block;
      width: 60px;
      top: -50px;
      left: 20px;
      transform: rotate(-10deg);
    }

    ${lgUp} {
      width: 70px;
      left: -20px;
      top: -20px;
    }
  }

  .heart4 {
    display: none;

    ${mdUp} {
      display: block;
      width: 72px;
      top: 0px;
      right: 40px;
      transform: rotate(30deg);
    }
  }
`;
