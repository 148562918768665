export const breakpoints = {
  xs: "(min-width: 375px)",
  sm: "(min-width: 576px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1080px)",
  xl: "(min-width: 1410px)",
  mdDown: "(max-width: 767px)",
};

export const smUp = `@media screen and ${breakpoints.sm}`;
export const mdUp = `@media screen and ${breakpoints.md}`;
export const lgUp = `@media screen and ${breakpoints.lg}`;
export const xlUp = `@media screen and ${breakpoints.xl}`;
export const mdDown = `@media screen and ${breakpoints.mdDown}`;
