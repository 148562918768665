import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 400px;
  padding-top: 8px;
  position: relative;
  width: 100%;
`;

const BaseInput = styled.input`
  border-radius: 8px;
  display: block;
  font-size: 13px;
  font-weight: 500;
  height: 64px;
  padding: 0 24px;
  transition: all 0.2s ease-out;
  width: 100%;
`;

export const Input = styled(BaseInput)`
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.background};
  outline: none;

  :focus,
  :hover {
    border: 1px solid ${({ theme }) => theme.input.border};
  }

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }

  ${({ isFocused }) =>
    isFocused &&
    `
    ::placeholder {
      visibility: hidden;
  }
  `}

  ${({ error, theme }) =>
    error &&
    `
  border: 1px solid ${theme.primary};

  :focus,
  :hover {
    border: 1px solid ${theme.primary};

  }
  `}

  ${({ disabled, theme }) =>
    disabled &&
    `
    background-color: ${theme.white};
    border: 1px solid ${({ theme }) => theme.input.border};
    color: ${theme.disabled};

    :focus,
  :hover {
    border: 1px solid ${({ theme }) => theme.input.border};

  }
  `}
`;

export const Label = styled.label`
  background: ${({ theme }) => theme.white};
  border-radius: 16px;
  color: ${({ theme }) => theme.text};
  display: inline-grid;
  font-size: 12px;
  height: 16px;
  left: 14px;
  line-height: 12px;
  opacity: 0;
  padding: 0 10px;
  place-items: center;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-out;

  ${({ visible }) =>
    visible &&
    `
      opacity: 1;
  `}
`;

export const ErrorMsg = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 12px;
  height: 24px;
  padding: 6px 0 0 24px;
`;

export const DarkInputWrapper = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const DarkLabel = styled.label`
  color: ${({ theme }) => theme.text};
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
`;

export const DarkInput = styled(BaseInput)`
  background-color: ${({ theme }) => theme.input.dark};
  border: none;
  color: ${({ theme }) => theme.white};

  ::placeholder {
    color: ${({ theme }) => theme.input.darkPlaceholder};
  }
`;
