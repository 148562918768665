import React, { useState, useEffect } from "react";
import { isChrome, isFirefox } from "react-device-detect";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import * as Styled from "./HeroSection.styles";
import { InnerContainer } from "src/components";
import { Button } from "src/components/common";
import Chevron from "src/assets/svgs/chevron.svg";
import heroPovertyDesktop from "src/assets/images/hero-poverty-desktop.png";
import heroPovertyMobile from "src/assets/images/hero-poverty-mobile.png";
import heroCovidDesktop from "src/assets/images/hero-covid-desktop.png";
import heroCovidMobile from "src/assets/images/hero-covid-mobile.png";
import heroAnimalsDesktop from "src/assets/images/hero-animals-desktop.png";
import heroAnimalsMobile from "src/assets/images/hero-animals-mobile.png";
import { sections } from "src/constants/constants";
import { LinkButton } from "../LinkButton/LinkButton";

const PROBLEMS = [
  {
    title: `Fight poverty <span>for free with Altruisto</span>`,
    paragraph: `<span>Just</span> install our Chrome extension <span>and when you buy something online, people in extreme poverty will get</span> medicines, bed nets, or financial aid<span>. No extra cost for you, huge difference for people in need!</span>`,
    bgImg: {
      mobile: heroPovertyMobile,
      desktop: heroPovertyDesktop,
    },
  },
  {
    title: `Fight COVID-19 <span>for free with Altruisto</span>`,
    paragraph: `<span>Just</span> install our Chrome extension <span>and when you buy something online, people at risk of COVID-19 in Africa will get</span> clean water, soap, and medical kits<span>. No extra cost for you, huge difference for people in need!</span>`,
    bgImg: {
      mobile: heroCovidMobile,
      desktop: heroCovidDesktop,
    },
  },
  {
    title: `Fight for animals <span>for free with Altruisto</span>`,
    paragraph: `<span>Just</span> install our Chrome extension <span>and when you buy something online, charities fighting for animals rights will get extra funds. No extra cost for you, huge difference for animals in need!</span>`,
    bgImg: {
      mobile: heroAnimalsMobile,
      desktop: heroAnimalsDesktop,
    },
  },
];

const TextContent = ({ id, currentProblemId }) => (
  <Styled.TextWrapper id={id} currentProblemId={currentProblemId}>
    <Styled.Title dangerouslySetInnerHTML={{ __html: PROBLEMS[id].title }} />
    <Styled.Subtitle>No extra cost for you</Styled.Subtitle>
    <Styled.Paragraph
      dangerouslySetInnerHTML={{ __html: PROBLEMS[id].paragraph }}
    />
  </Styled.TextWrapper>
);

const HeroSection = ({ observerRef }) => {
  useEffect(() => {
    var i = 0;
    const interval = setInterval(() => {
      if (i < 2) {
        i++;
      } else {
        i = 0;
      }
      setCurrentProblemId(i);

      return () => clearInterval(interval);
    }, 4000);
  }, []);

  const [currentProblemId, setCurrentProblemId] = useState(0);

  return (
    <Styled.CustomOuterContainer>
      {PROBLEMS.map(({ bgImg }, i) => (
        <Styled.BgImg
          key={i}
          id={i}
          currentProblemId={currentProblemId}
          url={bgImg}
        />
      ))}
      <Styled.IntersectionObserver ref={observerRef} />
      <Styled.Gradient1 />
      <Styled.Gradient2 />
      <InnerContainer>
        <Styled.Content>
          <Styled.TextPlaceholder>
            {PROBLEMS.map((e, i) => (
              <TextContent key={i} id={i} currentProblemId={currentProblemId} />
            ))}
          </Styled.TextPlaceholder>
          <Styled.ButtonsWrapper>
            <LinkButton />
            <AnchorLink to={`/#${sections.problem}`}>
              <Button className="extension-link" blurred>
                Find out more
              </Button>
            </AnchorLink>
          </Styled.ButtonsWrapper>
          <Styled.ScrollBtn>
            <Chevron />
          </Styled.ScrollBtn>
        </Styled.Content>
      </InnerContainer>
    </Styled.CustomOuterContainer>
  );
};

export default HeroSection;
