import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 400px;
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.text};
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
  transition: all 0.2s ease-out;
`;

export const SelectBtn = styled.button`
  height: 48px;
  width: 48px;
  background-color: ${({ theme, dark }) =>
    dark ? theme.input.selectButtonDark : theme.white};
  border-radius: 6px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  color: ${({ theme, dark }) => (dark ? theme.white : theme.primary)};
  display: grid;
  place-items: center;
`;

export const Select = styled.select`
  height: 64px;
  width: 100%;
  background-color: ${({ theme, dark }) =>
    dark ? theme.input.dark : theme.background};
  border-radius: 8px;
  border: none;
  color: ${({ theme, dark }) => (dark ? theme.white : theme.secondary)};
  padding: 0 24px;
  display: block;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  background-image: ${({ arrowUrl }) => `url(${arrowUrl})`};
  background-repeat: no-repeat;
  background-position: right 10px center;

  ::-ms-expand {
    display: none;
  }
  -moz-appearance: none;
  -webkit-appearance: none;

  :hover {
    border-color: #888;
  }
  :focus {
    border-color: #aaa;
    outline: none;
  }
  option {
    font-weight: normal;
  }
`;
