import React, { useState } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import * as Styled from "./Header.styles";
import Logo from "src/assets/svgs/logo.svg";
import LogoDesktop from "src/assets/svgs/logo-desktop.svg";
import Hamburger from "src/assets/svgs/hamburger.svg";
import { Button } from "src/components/common";
import { links, sections } from "src/constants/constants";
import { useReferredBy } from "../../hooks/useReferredBy";

const menuItems = [
  {
    name: "Problem",
    id: sections.problem,
  },
  {
    name: "Solution",
    id: sections.solution,
  },
  {
    name: "Why us",
    id: sections.whyUs,
  },
  {
    name: "Partners",
    id: sections.partners,
  },
  {
    name: "Faq",
    id: sections.faq,
  },
  {
    name: "Blog",
    href: links.blog,
  },
];

const MobileNav = ({ observerInView }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const withBackground = menuVisible || !observerInView;
  const referredBy = useReferredBy();

  return (
    <Styled.Wrapper withBackground={withBackground}>
      <Styled.ContentMobile as="nav" role="navigation">
        <Logo />
        <Styled.MenuBtn
          id="toggle"
          onClick={() => setMenuVisible(!menuVisible)}
        >
          <Hamburger />
        </Styled.MenuBtn>
        <Styled.MobileMenuWrapper visible={menuVisible}>
          <Styled.MobileMenu>
            {menuItems.map((item) => (
              <Styled.MobileMenuItem
                key={item.name}
                onClick={() => setMenuVisible(false)}
              >
                {item.href ? (
                  <a href={item.href} rel="noopener noreferrer" target="_blank">
                    <span>{item.name}</span>
                  </a>
                ) : (
                  <AnchorLink to={`/#${item.id}`}>
                    <span>{item.name}</span>
                  </AnchorLink>
                )}
              </Styled.MobileMenuItem>
            ))}
          </Styled.MobileMenu>
          <Styled.ButtonsWrapper>
            <a href={links.signup(referredBy)} rel="noopener noreferrer">
              <Button link fullWidth>
                Sign up for free
              </Button>
            </a>
            <a href={links.signin(referredBy)} rel="noopener noreferrer">
              <Button blurred fullWidth link>
                Sign in
              </Button>
            </a>
          </Styled.ButtonsWrapper>
        </Styled.MobileMenuWrapper>
      </Styled.ContentMobile>
    </Styled.Wrapper>
  );
};

const DesktopNav = ({ observerInView }) => {
  const referredBy = useReferredBy();

  return (
    <Styled.WrapperDesktop withBackground={!observerInView}>
      <Styled.DesktopNav>
        <LogoDesktop />
        <Styled.DesktopMenu>
          {menuItems.map((item, i) => (
            <React.Fragment key={i}>
              {item.href ? (
                <li key={item.name}>
                  <a href={item.href}>{item.name}</a>
                </li>
              ) : (
                <li key={i}>
                  <AnchorLink to={`/#${item.id}`}>
                    <span>{item.name}</span>
                  </AnchorLink>
                </li>
              )}
            </React.Fragment>
          ))}
        </Styled.DesktopMenu>
        <Styled.DesktopButtonsWrapper>
          <a href={links.signin(referredBy)} rel="noopener noreferrer">
            <Styled.BlurredButton small link href={links.signin(referredBy)}>
              Sign in
            </Styled.BlurredButton>
          </a>
          <a href={links.signup(referredBy)} rel="noopener noreferrer">
            <Styled.SignupButton
              small
              link
              href={links.signup(referredBy)}
              observerInView={observerInView}
            >
              Sign up for free
            </Styled.SignupButton>
          </a>
        </Styled.DesktopButtonsWrapper>
      </Styled.DesktopNav>
    </Styled.WrapperDesktop>
  );
};

const Header = ({ observerInView }) => {
  return (
    <>
      <DesktopNav observerInView={observerInView} />
      <MobileNav observerInView={observerInView} />
    </>
  );
};

export default Header;
