import React, { useState } from "react";
import PropTypes from "prop-types";

import * as Styled from "./TextInput.styles";

const TextInput = ({
  label,
  placeholder,
  id,
  disabled,
  dark,
  noError,
  value,
  setValue,
  error,
  name,
  setError,
  type,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {dark ? (
        <Styled.DarkInputWrapper>
          <Styled.DarkLabel
            htmlFor={id}
            visible={isFocused || value.length > 0}
          >
            {label}
          </Styled.DarkLabel>
          <Styled.DarkInput
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            isFocused={isFocused}
            type={type || "text"}
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            error={!!error}
          />
        </Styled.DarkInputWrapper>
      ) : (
        <Styled.Wrapper>
          <Styled.Label htmlFor={id} visible={isFocused || value.length > 0}>
            {label}
          </Styled.Label>
          <Styled.Input
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            isFocused={isFocused}
            type={type || "text"}
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            error={!!error}
            disabled={disabled}
          />
          {!noError && (
            <Styled.ErrorMsg>{!!error && <span>{error}</span>}</Styled.ErrorMsg>
          )}
        </Styled.Wrapper>
      )}
    </>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  noError: PropTypes.bool,
};

export default TextInput;
