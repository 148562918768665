import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";

export const Content = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 22px;

  ${mdUp} {
    justify-content: space-between;
    padding-top: 0;
  }

  ${lgUp} {
  }
`;

export const ImgRelativeWrapper = styled.div`
  position: relative;
  height: 150px;
  width: 120px;
  margin: 0 6px;

  ${mdUp} {
    height: 193px;
    width: 152px;
    margin: 0;

    :not(:last-child) {
      margin-right: 20px;
    }
  }

  ${lgUp} {
    width: 280px;
    height: 356px;
  }

  :nth-child(1) {
    display: none;
  }

  :nth-child(3) {
    margin-top: 40px;
  }

  :nth-child(4) {
    display: none;
  }

  ${mdUp} {
    :nth-child(1) {
      display: block;
      margin-top: 40px;
    }

    :nth-child(2) {
      display: block;
    }

    :nth-child(3) {
      margin-top: 40px;
    }

    :nth-child(4) {
      display: block;
      margin-top: 80px;
    }
  }

  > svg {
    position: absolute;
    mix-blend-mode: multiply;
    height: auto;
    width: auto;

    &.stroke1 {
      top: -22px;
      width: 128px;
      z-index: 5;
      left: -40px;

      ${mdUp} {
        width: 178px;
        left: -240px;
        top: 16px;
        transform: rotate(-15deg);
      }

      ${lgUp} {
        width: 246px;
        left: -370px;
        top: 10px;
      }
    }

    &.stroke1y {
      bottom: -30px;
      width: 135px;
      z-index: 2;
      transform: scale(-1, -1);
      right: -40px;

      ${mdUp} {
        width: 175px;
        right: -230px;
        bottom: -70px;
        transform: rotate(-10deg) scale(-1, -1);
      }

      ${lgUp} {
        width: 248px;
        right: -370px;
        bottom: -60px;
      }
    }
  }
`;

export const ImgWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

export const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #412325 100%);
  bottom: 0;
  height: 70%;
  left: 0;
  position: absolute;
  width: 100%;
`;
