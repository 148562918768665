import styled from "styled-components";

import { mdUp, lgUp, xlUp } from "src/styles/breakpoints";

export const Container = styled.section`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  padding: 80px 0 180px;

  ${mdUp} {
    padding: 120px 0 193px;
  }

  ${xlUp} {
    padding: 170px 0 302px;
    justify-content: space-around;
  }

  > * {
    flex-shrink: 0;
  }
`;

export const ImgRelativeWrapper = styled.div`
  position: relative;
  height: 150px;
  width: 120px;

  ${mdUp} {
    height: 250px;
    width: 200px;
  }

  ${xlUp} {
    height: 356px;
    width: 280px;
  }

  :not(:last-child) {
    margin-right: 12px;
  }

  :nth-child(1) {
    margin-top: 90px;
  }

  :nth-child(2) {
    margin-top: 50px;
  }

  :nth-child(4) {
    margin-top: 40px;
  }

  :nth-child(5) {
    margin-top: 90px;
  }

  :nth-child(6) {
    margin-top: 50px;
  }

  ${mdUp} {
    :not(:last-child) {
      margin-right: 14px;
    }

    :nth-child(1) {
      margin-top: 144px;
    }

    :nth-child(2) {
      margin-top: 80px;
    }

    :nth-child(4) {
      margin-top: 64px;
    }

    :nth-child(5) {
      margin-top: 144px;
    }

    :nth-child(6) {
      margin-top: 80px;
    }
  }

  ${xlUp} {
    :not(:last-child) {
      margin-right: 20px;
    }

    :nth-child(1) {
      margin-top: 180px;
    }

    :nth-child(2) {
      margin-top: 100px;
    }

    :nth-child(4) {
      margin-top: 80px;
    }

    :nth-child(5) {
      margin-top: 180px;
    }

    :nth-child(6) {
      margin-top: 100px;
    }
  }

  > svg {
    position: absolute;
    mix-blend-mode: multiply;
    height: auto;
    width: auto;

    &.stroke1 {
      top: -30px;
      width: 128px;
      z-index: 5;
      transform: scale(1, -1);

      ${mdUp} {
        width: 178px;
        left: 10px;
        top: -46px;
      }

      ${xlUp} {
        width: 236px;
        left: 20px;
      }
    }

    &.stroke2 {
      bottom: -40px;
      width: 171px;
      z-index: -1;

      ${mdUp} {
        width: 254px;
        right: -90px;
        bottom: -20px;
      }

      ${xlUp} {
        left: -100px;
        width: 303px;
        bottom: -90px;
      }
    }

    &.hearts-left {
      height: 120px;
      top: -138px;

      ${mdUp} {
        height: 168px;
        top: -180px;
        left: 50px;
      }

      ${xlUp} {
        height: 246px;
        top: -240px;
        left: -90px;
      }
    }

    &.hearts-right {
      height: 120px;
      bottom: -140px;
      left: 20px;

      ${mdUp} {
        height: 168px;
        bottom: -180px;
        left: 30px;
      }

      ${xlUp} {
        height: 246px;
        bottom: -260px;
        left: 190px;
      }
    }
  }
`;

export const ImgWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

export const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #412325 100%);
  bottom: 0;
  height: 70%;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const Description = styled.div`
  display: none;

  ${mdUp} {
    display: block;
  }

  bottom: 0;
  color: ${({ theme }) => theme.white};
  font-size: 18px;
  font-weight: 500;
  padding: 0 20px 20px;
  position: absolute;

  span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-top: 4px;
    opacity: 0.8;
  }

  ${xlUp} {
    padding: 0 30px 30px;

    span {
      font-size: 14px;
      margin-top: 8px;
    }
  }
`;
