import styled from "styled-components";

import { mdUp, lgUp } from "src/styles/breakpoints";
import { OuterContainer as BaseOuterContainer } from "src/components/Layout/Layout.styles";
import bgMobile from "src/assets/images/cta-bg-mobile.png";
import bgTablet from "src/assets/images/cta-bg-tablet.png";
import bgDesktop from "src/assets/images/cta-bg-desktop.png";

export const OuterContainer = styled(BaseOuterContainer)`
  background-image: linear-gradient(
      rgba(21, 15, 12, 0.5),
      rgba(21, 15, 12, 0.5)
    ),
    linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(253, 56, 66, 0.08) 100%
    ),
    linear-gradient(101.57deg, #150f0c 39.02%, rgba(21, 15, 12, 0) 80.33%),
    url(${bgMobile});
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
  background-position: right bottom;

  ${mdUp} {
    background-image: linear-gradient(
        rgba(21, 15, 12, 0.5),
        rgba(21, 15, 12, 0.5)
      ),
      linear-gradient(
        180deg,
        rgba(196, 196, 196, 0) 0%,
        rgba(253, 56, 66, 0.08) 100%
      ),
      linear-gradient(101.57deg, #150f0c 39.02%, rgba(21, 15, 12, 0) 80.33%),
      url(${bgTablet});
  }

  ${lgUp} {
    background-image: linear-gradient(
        rgba(21, 15, 12, 0.5),
        rgba(21, 15, 12, 0.5)
      ),
      linear-gradient(
        180deg,
        rgba(196, 196, 196, 0) 0%,
        rgba(253, 56, 66, 0.08) 100%
      ),
      linear-gradient(101.57deg, #150f0c 39.02%, rgba(21, 15, 12, 0) 80.33%),
      url(${bgDesktop});
  }

  .stroke1 {
    position: absolute;
    width: 180px;
    top: -60px;
    transform: scaleY(-1);
    /* can't use it, it somehow interferes with backdrop-filter used in blutted button */
    /* mix-blend-mode: multiply; */
    left: 40%;

    ${mdUp} {
      width: 216px;
      left: 55%;
    }

    ${lgUp} {
      width: 250px;
      left: 60%;
    }
  }

  .stroke1--2 {
    position: absolute;
    width: 180px;
    bottom: -60px;
    transform: scaleX(-1);
    /* mix-blend-mode: multiply; */
    right: 45%;

    ${mdUp} {
      width: 216px;
      transform: scaleX(1);
      bottom: -65px;
      left: 10%;
    }

    ${lgUp} {
      width: 250px;
      left: calc(50% - 600px);
    }
  }
`;

export const Content = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 70px 0;
  width: 100%;

  ${mdUp} {
    max-width: 500px;
    padding: 80px 0;
  }

  ${lgUp} {
    max-width: 900px;
    padding: 100px 0;
  }

  p {
    color: ${({ theme }) => theme.white};
    font-weight: 400;
    line-height: 24px;
    margin-top: 26px;
    opacity: 0.7;
    padding: 0 16px;
    text-align: center;

    ${mdUp} {
      line-height: 26px;
      margin-top: 30px;
      padding: 0;
    }

    ${lgUp} {
      margin-top: 40px;
      max-width: 500px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${mdUp} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
  }

  ${lgUp} {
    margin-top: 50px;
  }

  > :first-child {
    margin-bottom: 20px;

    ${mdUp} {
      margin: 0 20px 0 0;
    }
  }
`;
